import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { customResponse } from '../_models/common/custom-response.model';
import { HttpStatus } from '../_models/enums/httpstatus';
import { changePasswordDirectRequest } from '../_models/requestModel/change-password-direct-request.model';
import { ForgotPasswordRequestModel, resetPasswordRequestModel, verifyActivationCodeRequestModel } from '../_models/requestModel/forgot-password-request-model';
import { LoginRequest } from '../_models/requestModel/login-request.model';
import { ApiUriResource } from '../_models/resources/api-uri-resource';
import { User } from '../_models/responseModels/user.model';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  private authLocalStorage = `${environment.appVersion}-${environment.userKey}`;
  private authTokenLocalStorage = `${environment.appVersion}-${environment.AppLocalstorageTokenKeyName}`;

  currentUser$: Observable<User | null>;
  currentUserSubject: BehaviorSubject<User | null>;

  constructor(private router: Router, private httpService: HttpService) {
    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  Login<T>(request: LoginRequest): Observable<{ Data: T; Status: boolean }> {
    return this.httpService
      .getHttpResponse<T>(HttpStatus.POST, request, ApiUriResource.Login, true)
      .pipe(
        map((response: { Data: T; Status: boolean }) => {
          let res = response.Data as unknown as customResponse;
          if (res.TResult) {
            const SetData = res.ResponseData as User;
            SetData.expiresIn = new Date(
              new Date().setDate(new Date().getDate() + 1)
            );
            this.currentUserSubject.next(SetData);
            const result = this.setAuthUserLocalStorage(SetData);
          }
          return response
        })
      );
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/auth/login']).then(() => { });
  }

  setAuthUserLocalStorage(userResult: User): boolean {
    if (userResult && userResult.Token) {
      localStorage.setItem(
        this.authLocalStorage,
        btoa(JSON.stringify(userResult))
      );
      localStorage.setItem(this.authTokenLocalStorage, userResult.Token);
      return true;
    }
    return false;
  }

  getUserByToken(userModel: User): Observable<User | undefined> {
    if (!userModel.expiresIn) {
      return of(undefined);
    } else if (userModel.expiresIn) {
      if (new Date() > new Date(userModel.expiresIn)) {
        return of(undefined);
      }
    }
    return of(userModel);
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value ?? new User();
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  private getAuthFromLocalStorage(): User | undefined {
    try {
      const listing = localStorage.getItem(this.authLocalStorage);
      if (listing != null) {
        return JSON.parse(atob(listing));
      }
      return undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  checkUserValid(): Observable<User | undefined> {
    const user = this.getAuthFromLocalStorage();
    if (!user || !user.Token) {
      return of(undefined);
    }
    return this.getUserByToken(user).pipe(
      map((ur: User | undefined) => {
        if (ur) {
          this.currentUserSubject = new BehaviorSubject<User | null>(ur);
        } else {
          this.logout();
        }
        return ur;
      }),
      finalize(() => { })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  forgotPassword<T>(request: ForgotPasswordRequestModel): Observable<{ Data: T; Status: boolean }> {
    return this.httpService.getHttpResponse<T>(HttpStatus.POST, request, ApiUriResource.forgotPasswordURL, true)
      .pipe(
        map((response: { Data: T; Status: boolean }) => {
          return response;
        })
      );
  }

  isValidActivationCode<T>(request: verifyActivationCodeRequestModel): Observable<{ Data: T; Status: boolean }> {
    return this.httpService.getHttpResponse<T>(HttpStatus.POST, request, ApiUriResource.isValidActivationCodeURL, true)
      .pipe(
        map((response: { Data: T; Status: boolean }) => {
          return response;
        })
      );
  }

  resetPassword<T>(request: resetPasswordRequestModel): Observable<{ Data: T; Status: boolean }> {
    return this.httpService.getHttpResponse<T>(HttpStatus.POST, request, ApiUriResource.resetPasswordURL, true)
      .pipe(
        map((response: { Data: T; Status: boolean }) => {
          return response;
        })
      );
  }

  changePassword<T>(request: changePasswordDirectRequest): Observable<{ Data: T; Status: boolean }> {
    return this.httpService.getHttpResponse<T>(HttpStatus.POST, request, ApiUriResource.changePasswordURL, true)
      .pipe(
        map((response: { Data: T; Status: boolean }) => {
          return response;
        })
      );
  }
}
