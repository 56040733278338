import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appAlphaNumeric]'
})
export class AlphaNumericDirective {
    @Input() operationType: string = '';

    @HostListener('keypress', ['$event'])
    onKeypress(event: { key: string; preventDefault: () => void; which: number; keyCode: number; }): void {
        if (this.operationType !== undefined && this.operationType !== null && this.operationType !== '') {
            if (this.operationType.toUpperCase() === 'ALPHANUMERIC') {
                const isAlphaNumeric = /^[A-Za-z0-9]+$/.test(event.key);
                if (!isAlphaNumeric) {
                    event.preventDefault();
                }
            } else if (this.operationType.toUpperCase() === 'NUMBERS') {
                const isNumeric = /^\d+$/.test(event.key);
                if (!isNumeric) {
                    event.preventDefault();
                }
            } else if (this.operationType.toUpperCase() === 'ALPHABETS') {
                const isAlpha = /^[A-Za-z]+$/.test(event.key);
                if (!isAlpha) {
                    event.preventDefault();
                }
            } else if (this.operationType.toUpperCase() === 'ALPHAWITHSPACE') {
                const isAlphaSpace = /^[a-zA-Z ]*$/.test(event.key);
                if (!isAlphaSpace) {
                    event.preventDefault();
                }
            }
            else if (this.operationType.toUpperCase() === 'ALPHANUMERICWITHSPACE') {
                const isAlphaNumericSpace = /^[a-zA-Z0-9 ]*$/.test(event.key);
                if (!isAlphaNumericSpace) {
                    event.preventDefault();
                }
            }
            else if (this.operationType.toUpperCase() === 'ONLYSPACENOTALLOW') {
                if (event.which === 32 || event.keyCode === 32) {
                    event.preventDefault();
                }
            }
        } else {
            if (event.which === 32 || event.keyCode === 32) {
                event.preventDefault();
            }
        }
    }
}
