<div class="listing">
  <div class="topbar">
    <div class="titleHead">My Profile<span>Setting</span></div>
    <div class="addNew"><a href="javascript:void(0)" onclick="history.back()"><img
          src="../.././../../assets/images/back.png" alt="Back"></a>
    </div>
  </div>

  <div class="addNewOuter">
    <div class="fomrOuter">
      <form #superAdminProfile="ngForm" (ngSubmit)="updateSuperAdminProfile(superAdminProfile)">
        <div class="row">
          <div class="col-lg-12">
            <p class="font-weight-bolder"><span class="red">*</span> all fields are required</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="formRow">
              <label>First Name <span>*</span></label>
              <input #FirstName='ngModel' type="text" [(ngModel)]="model.FirstName" appTrim operationType="ALPHANUMERIC"
                appAlphaNumeric class="form-control disabled" [ngClass]="{' is-invalid': ((FirstName.touched || FirstName.dirty) && FirstName.invalid),
               ' is-valid': ((FirstName.touched || FirstName.dirty) && FirstName.valid)}" name="FirstName"
                maxlength="30" placeholder="First Name" required [disabled]="true" tabindex="1" autofocus>
              <ng-container [ngTemplateOutletContext]="{validation: 'required',message: 'Please Enter First Name',control:
                FirstName}" [ngTemplateOutlet]="formError">
              </ng-container>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="formRow">
              <label>Last Name <span>*</span></label>
              <input #LastName='ngModel' type="text" [(ngModel)]="model.LastName" appTrim operationType="ALPHANUMERIC"
                appAlphaNumeric class="form-control disabled"
                [ngClass]="{' is-invalid': ((LastName.touched ||
                LastName.dirty) &&  LastName.invalid), ' is-valid': ((LastName.touched || LastName.dirty) && LastName.valid)}" name="LastName" maxlength="30"
                placeholder="Last Name" required [disabled]="true" tabindex="2" autofocus>
              <ng-container [ngTemplateOutletContext]="{validation: 'required',message: 'Please Enter Last Name',control:
                LastName}" [ngTemplateOutlet]="formError">
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="formRow">
              <label> Email Address <span>*</span></label>
              <input #Email='ngModel' type="text" [(ngModel)]="model.Email" name="Email" maxlength="100"
                class="form-control" appTrim emailValidate [ngClass]="{'
                is-invalid':
                ((Email.touched || Email.dirty) && Email.invalid),
                ' is-valid': ((Email.touched || Email.dirty) && Email.valid)}" placeholder="Email" required
                tabindex="3" autofocus>
              <span *ngIf="(Email.touched || Email.dirty) && Email.errors?.emailInvalid  && !Email.errors?.valueNull"
                style="color: red;">
                Please enter correct email
              </span>
              <ng-container [ngTemplateOutletContext]="{validation: 'required',message: 'Please Enter Email',control:
                Email}" [ngTemplateOutlet]="formError">
              </ng-container>

            </div>
          </div>
          <div class="col-lg-3">
            <div class="formRow">
              <label>Phone Numer</label>
              <input #PhoneNumber='ngModel' type="text" operationType="NUMBERS" appAlphaNumeric
                [(ngModel)]="model.PhoneNumber" class="form-control" name="PhoneNumber" [ngClass]="{' is-invalid': (PhoneNumber.touched || PhoneNumber.dirty) &&
                PhoneNumber.errors?.phoneNumberInvalid,
                ' is-valid': (PhoneNumber.touched || PhoneNumber.dirty) && PhoneNumber.errors?.phoneNumberInvalid}"
                mask="(000) 000-0000" [showMaskTyped]="true" [clearIfNotMatch]="true" appMaskingBlur tabindex="4"
                autofocus>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <div class="formRow">
                <label>User Name</label>
                <input #UserName='ngModel' type="text" [(ngModel)]="model.UserName" class="form-control" disabled
                  name="UserName" tabindex="5" autofocus>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="formRow">
              <button type="submit" class="basicBtn" [disabled]="isBusy" tabindex="6">
                <ng-container *ngIf="isBusy">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </ng-container>
                <ng-container *ngIf="!isBusy">Update</ng-container>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <span class="lable text-danger h6 ml-3 font-weight-bold" style="font-size: 17px;">{{ message }}</span>
  </ng-container>
</ng-template>
