import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = new Subject<boolean>();

  // tslint:disable-next-line:typedef
  show() {
    this.isLoading.next(true);
  }

  // tslint:disable-next-line:typedef
  hide() {
    this.isLoading.next(false);
  }
}
