import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { NgPaginationComponent } from './components/ng-pagination/ng-pagination.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ShortingComponent } from './components/shorting/shorting.component';
import { AlphaNumericDirective } from '../../_directive/alpha-numeric.directive';
import { EmailValidationDirective } from 'src/app/_directive/emailvalidatte.directive';
import { TrimValueAccessorDirective } from 'src/app/_directive/trim.directive';
import { PhoneNumberValidatorDirective } from 'src/app/_directive/phonenumbervalidate.directive';
import { myProfileComponent } from './components/my-profile/my-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskDirective, NgxMaskModule } from 'ngx-mask';
import { changePasswordComponent } from './components/change-password/change-password.component';
import { SearchFilterPipe } from 'src/app/_directive/search-filter.pipe';
import { MaskingBlurDirective } from 'src/app/_directive/maskingBlur.directive';
import { DateIsvalidateDirective } from 'src/app/_directive/date-isvalidate.directive';
import { CanvasJSChart } from './components/canvas-js-chart/canvas-js-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { NumPipe } from 'src/app/_directive/num.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartModule } from 'angular-highcharts'
@NgModule({
  declarations: [
    LoaderComponent,
    NgPaginationComponent,
    PaginatorComponent,
    ShortingComponent,
    AlphaNumericDirective,
    EmailValidationDirective,
    TrimValueAccessorDirective,
    PhoneNumberValidatorDirective,
    myProfileComponent,
    changePasswordComponent,
    SearchFilterPipe,
    MaskingBlurDirective,
    DateIsvalidateDirective,
    CanvasJSChart,
    NumPipe,
  ],
  exports: [
    LoaderComponent,
    PaginatorComponent,
    ShortingComponent,
    AlphaNumericDirective,
    EmailValidationDirective,
    TrimValueAccessorDirective,
    PhoneNumberValidatorDirective,
    SearchFilterPipe,
    MaskingBlurDirective,
    DateIsvalidateDirective,
    MaskDirective,
    CanvasJSChart,
    NgChartsModule,
    NumPipe,
    NgMultiSelectDropDownModule,
    ChartModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
  ]
})


export class SharedModule { }