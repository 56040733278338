 <div class="navigation-bottom">
   <nav aria-label="Page navigation example">
     <ul class="pagination">
       <app-ng-pagination style="display: flex;" [collectionSize]="paginator.Total" [(page)]="paginator.Page"
         [maxSize]="4" [rotate]="true" [boundaryLinks]="true" [pageSize]="paginator.PageSize"
         (pageChange)="pageChange($event)">
       </app-ng-pagination>
     </ul>
   </nav>

   <div class="perPage">
     <select style="width: 85px;" [(ngModel)]="paginator.PageSize" (change)="sizeChange()">
       <option class="btn" *ngFor="let ps of pageSizes">
         {{ ps }}
       </option>
     </select>
     <span class="react-bootstrap-table-pagin ation-total">&nbsp;Showing rows {{ paginator.Page }}
       to&nbsp;{{ paginator.PageSize }} of&nbsp;{{ paginator.Total }}</span>
   </div>
 </div>
 
