import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SortDirection } from '../../../../_models/common/paginator';

@Component({
  selector: 'app-shorting',
  templateUrl: './shorting.component.html',
  styleUrls: ['./shorting.component.css']
})
export class ShortingComponent implements OnInit, OnChanges {

  @Input() column: string | undefined;
  @Input() activeColumn: string | undefined;
  @Input() activeDirection!: SortDirection | string;
  @Output() sort: EventEmitter<string> = new EventEmitter<string>();
  isActive = false;

  constructor(private el: ElementRef) { }

  ngOnChanges(): void {
    const parent = this.el.nativeElement.parentElement;
    if (!parent) {
      return;
    }

    // Load css classes
    parent.classList.add('sorting_disabled');
    parent.classList.remove('sortable-active');
    if (this.column === this.activeColumn) {
      parent.classList.add('sortable-active');
    }

    // load icons
    this.isActive = this.column === this.activeColumn;
  }

  ngOnInit(): void {
    const parent = this.el.nativeElement.parentElement as Element;
    if (!parent) {
      return;
    }

    parent.addEventListener('click', () => {
      this.sort.emit(this.column);
    });
  }
}
