import { Component, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';

import { DOCUMENT } from '@angular/common';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnDestroy {
  public isLoadingShow = true;
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private loaderService: LoaderService
  ) {
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.isLoadingShow = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isLoadingShow = false;
        }
      },
      () => {
        this.isLoadingShow = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.isLoadingShow = false;
  }
}
