import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/responseModels/user.model';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private authLocalStorage = `${environment.appVersion}-${environment.userKey}`;
  currentUser: User | null;
  constructor(private authorise: AuthService) {
    this.currentUser = null;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      this.currentUser = this.authorise.currentUserValue;
      if (localStorage.getItem(this.authLocalStorage) == null) {
        this.currentUser = null;
      }
      if (this.currentUser) {
        // logged in so return true
        if (!this.currentUser.expiresIn) {
          this.authorise.logout();
          return false;
        } else if (new Date(this.currentUser.expiresIn) < new Date()) {
          this.authorise.logout();
          return false;
        }
        return true;
      }
      this.authorise.logout();
      return false;
  }
}
