<div class="listing">
  <form [formGroup]="changePasswordForm" novalidate="novalidate" (ngSubmit)="changePassword()">
    <div class="topbar">
      <div class="titleHead">Change Password<span></span></div>
      <div class="addNew"><a href="javascript:void(0)" onclick="history.back()"><img
            src="../.././../../assets/images/back.png" alt="Back"></a></div>
    </div>
    <div class="addNewOuter"></div>
    <div class="row">
      <div class="col-lg-12">
        <p class="font-weight-bolder"><span class="red">*</span> all fields are required</p>
      </div>
    </div>
    <div class="row mb-4">
      <div class="formRow col-lg-4">
        <label>Old Password<span class="red">*</span></label>
        <input id="oldPassword" formControlName="oldPassword" clas="form-control" [ngClass]="{ ' is-invalid': changePasswordForm.controls['oldPassword'].invalid &&
        changePasswordForm.controls['oldPassword'].touched }" maxlength="15" [type]="ShowPWD ? 'text' : 'password'"
          title="Passwords must contain at least 8 characters, including uppercase, lowercase, special characters and numbers"
          placeholder="old Password" autocomplete="off" tabindex="1" autofocus required>
        <div class="passIcon">
          <span class="toggle-password material-symbols-outlined" (click)="ShowPWD = !ShowPWD">
            {{ShowPWD ? 'visibility_off' : 'visibility'}} </span>
        </div>

      </div>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'required',message: 'Please Enter Old Password',control:
        changePasswordForm.controls['oldPassword']}">
      </ng-container>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'minlength',message: 'Password should have at least 8 characters',control:
    changePasswordForm.controls['oldPassword']}">
      </ng-container>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'maxlength',message: 'Password should have Max 15 characters',control:
    changePasswordForm.controls['oldPassword']}">
      </ng-container>
    </div>

    <div class="row mb-4">
      <div class="formRow col-lg-4">
        <label>New Password<span class="red">*</span></label>
        <input id="newPassword" formControlName="newPassword" clas="form-control" [ngClass]="{ ' is-invalid': changePasswordForm.controls['newPassword'].invalid &&
        changePasswordForm.controls['newPassword'].touched }" maxlength="15" [type]="ShowNewPWD ? 'text' : 'password'"
          title="Passwords must contain at least 8 characters, including uppercase, lowercase, special characters and numbers"
          placeholder="new Password" autocomplete="off" tabindex="2" autofocus required>
        <div class="passIcon">
          <span class="toggle-password material-symbols-outlined" (click)="ShowNewPWD = !ShowNewPWD">
            {{ShowNewPWD ? 'visibility_off' : 'visibility'}} </span>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'required',message: 'Please Enter New Password',control:
        changePasswordForm.controls['newPassword']}">
      </ng-container>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'minlength',message: 'Password should have at least 8 characters',control:
    changePasswordForm.controls['newPassword']}">
      </ng-container>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'maxlength',message: 'Password should have Max 15 characters',control:
    changePasswordForm.controls['newPassword']}">
      </ng-container>
    </div>
    <div class="row mb-4">
      <div class="formRow col-lg-4">
        <label>Confirm Password<span class="red">*</span></label>
        <input id="confirmPassword" formControlName="confirmPassword" clas="form-control " [ngClass]="{ ' is-invalid': changePasswordForm.controls['confirmPassword'].invalid &&
        changePasswordForm.controls['confirmPassword'].touched }" maxlength="15" type="text" [type]="ShowConPWD ?
        'text':'password'" 
          title="Passwords must contain at least 8 characters, including uppercase, lowercase, special characters and numbers"
          placeholder="confirm Password" autocomplete="off" tabindex="3" autofocus required>
        <div class="passIcon">
          <span class="toggle-password material-symbols-outlined" (click)="ShowConPWD = !ShowConPWD">
            {{ShowConPWD ? 'visibility_off' : 'visibility'}} </span>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'required',message: 'Please Enter Confirm Password',control:
        changePasswordForm.controls['confirmPassword']}">
      </ng-container>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'minlength',message: 'Password should have at least 8 characters',control:
    changePasswordForm.controls['confirmPassword']}">
      </ng-container>
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{validation: 'maxlength',message: 'Password should have Max 15 characters',control:
    changePasswordForm.controls['confirmPassword']}">
      </ng-container>
    </div>
    <div class="formRow">
      <div class="d-flex btnOuter">
        <div class="submitBtn">
          <button type="submit" class="basicBtn" [disabled]="isBusy" tabindex="4">
            <ng-container *ngIf="isBusy">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </ng-container>
            <ng-container *ngIf="!isBusy">Change Password</ng-container>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <span class="lable text-danger h6 ml-3 font-weight-bold" style="font-size: 13px;">{{ message }}</span>
  </ng-container>
</ng-template>
