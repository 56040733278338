export class updateProfileRequestModel {
    LoginDetailId: number;
    FirstName: string;
    LastName: string;
    UserName: string;
    Email: string;
    PhoneNumber: string;
    IsActive: boolean;
    BranchId: number;
    constructor() {
        this.LoginDetailId = 0;
        this.FirstName = '';
        this.LastName = '';
        this.UserName = '';
        this.Email = '';
        this.PhoneNumber = '';
        this.IsActive = false;
        this.BranchId = 0;
    }
}
