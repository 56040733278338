import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { profileService } from 'src/app/_services/profile.service';
import { customResponse } from 'src/app/_models/common/custom-response.model';
import { sysadminConstants } from 'src/app/_models/common/sysadmin.constants';

import { User } from 'src/app/_models/responseModels/user.model';
import { updateProfileRequestModel } from 'src/app/_models/requestModel/update-profile-request-model';
import { adminConstants } from 'src/app/_models/common/admin.constatnts';
import { userConstants } from 'src/app/_models/common/user.constants';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/_services/auth.service';
import { Roles } from 'src/app/_models/enums/roles';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class myProfileComponent implements OnInit {

  //#region ===========> Variable Declaration <===========
  currentUser: User;
  model: updateProfileRequestModel = new updateProfileRequestModel();
  isBusy: boolean = false;
  LoginDetailId: number = 0;
  IsActive: number = 0;
  //#endregion

  //#region ===========> Constructor & OnInit Methods <===========
  constructor(private profileService: profileService,
    private router: Router,
    private authService: AuthService) {
    this.currentUser = new User();
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue;
    this.getSuperAdminProfile();
  }
  //#endregion

  //#region ===========> CRUD Operation <===========
  getSuperAdminProfile(): void {
    this.profileService.getSuperAdminProfile<customResponse>()
      .subscribe((response: { Data: customResponse }) => {
        if (response.Data.TResult) {
          this.model = response.Data.ResponseData as updateProfileRequestModel;
        }
      })
  }
  updateSuperAdminProfile(superAdminProfile: NgForm): void {
    this.isBusy = true;
    if (superAdminProfile.valid) {
      this.profileService.updateSuperAdminProfile<customResponse>(this.model)
        .subscribe((response: { Data: customResponse }) => {
          if (response.Data.TResult) {
            if (this.currentUser.RoleId === Roles.SystemAdmin) {
              this.router.navigateByUrl(sysadminConstants.dashboard);
            }
            else if (this.currentUser.RoleId === Roles.SuperAdmin) {
              this.router.navigateByUrl(adminConstants.dashboard);
            }
            else if (this.currentUser.RoleId === Roles.Admin) {
              this.router.navigateByUrl(userConstants.dashboard);
            }
            else if (this.currentUser.RoleId === Roles.User) {
              this.router.navigateByUrl('/user/dashboard');
            }
          }
          this.isBusy = false;
        })
    } else {
      (Object as any).values(superAdminProfile.controls).forEach((control: { markAsTouched: () => void; }) => {
        control.markAsTouched();
      });
      this.isBusy = false;
    }
  }
  //#endregion
}
