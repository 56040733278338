import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Paginator, PageSizes } from '../../../../_models/common/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  @Input() paginator: Paginator = new Paginator;
  @Output() paginate: EventEmitter<Paginator> = new EventEmitter();
  pageSizes: number[] = PageSizes;
  constructor() { }

  ngOnInit(): void {
  }
  pageChange(num: number): void {
    this.paginator.Page = num;
    this.paginate.emit(this.paginator);
  }

  sizeChange(): void {
    this.paginator.PageSize = +this.paginator.PageSize;
    this.paginator.Page = 1;
    this.paginate.emit(this.paginator);
  }

}
