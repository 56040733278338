import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
@Directive({
    selector: '[emailValidate]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: EmailValidationDirective,
        multi: true
    }]
})
// export class EmailValidationDirective {

//     constructor(private elementRef: ElementRef) {
//     }

//     @HostListener('keyup')
//     onKeyup(): any {
//         //return null;
//         // let value = this.elementRef?.nativeElement?.value;
//         // if (value) {
//         //     if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
//         //         return { 'emailInvalid': true }
//         //     }
//         //     else {
//         //         return null;
//         //     }
//         // }
//         // else {
//         //     return { 'valueNull': true };
//         // }
//     }
// }
export class EmailValidationDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        if (control.value) {
            if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(control.value)) {
                return { 'emailInvalid': true }
            }
            else {
                return null;
            }
        }
        else {
            return { 'valueNull': true };
        }
    }
}
