import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appMaskingBlur]'
})
export class MaskingBlurDirective {

  constructor(private renderer: Renderer2,
    private elementRef: ElementRef,
    private ngModel: NgModel) { }

  @HostListener('blur')
  onBlur(): void {
    let currentValue = this.elementRef?.nativeElement?.value?.replaceAll('_', '').trim();
    if (currentValue) {
      if (currentValue.length < this.elementRef.nativeElement.getAttribute('mask').length) {
        this.elementRef.nativeElement.value = '';
        this.ngModel.update.emit('')
      } else
        this.ngModel.update.emit(this.elementRef.nativeElement.value);
    }
  }
}
