import {
  BooleanNullable,
  DateNullable,
  NumberNullable,
  StringNullable,
} from '../common/customeDataType';

export class User {
  LoginDetailID?: NumberNullable;
  FirstName?: StringNullable;
  LastName?: StringNullable;
  UserName?: StringNullable;
  Email?: StringNullable;
  PhoneNumberNullable?: StringNullable;
  Token?: StringNullable;
  MobileToken?: StringNullable;
  IsFirstTimeLogin?: BooleanNullable;
  RoleId?: NumberNullable;
  Role?: StringNullable;
  IsActive?: BooleanNullable;
  CreatedOn?: DateNullable;
  CreatedBy?: NumberNullable;
  UpdatedOn?: DateNullable;
  UpdatedBy?: NumberNullable;
  expiresIn?: DateNullable;
  constructor() {
  }
}
