import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { alertService } from './alert.service';
import { customResponse } from './../_models/common/custom-response.model';
import { map, catchError, finalize } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpStatus } from './../_models/enums/httpstatus';
import { alertStatus } from '../_models/enums/alertstatus';
import { Observable, Subscriber, throwError } from 'rxjs';
import { ApiUriResource } from '../_models/resources/api-uri-resource';

const APIBaseUrl = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class HttpService {


  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private alertService: alertService
  ) { }

  getHttpResponse<T>(
    methodType: number,
    Data: any,
    URI: string,
    ShowMsg: boolean = false
  ): Observable<{ [x: string]: any; Data: T; Status: boolean }> {
    let headers = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    if (!headers.has('content-type')) {
      headers = headers.append('content-type', 'application/json');
    }

    const Token = localStorage.getItem(
      `${environment.appVersion}-${environment.AppLocalstorageTokenKeyName}`
    );
    if (Token !== null && Token !== undefined && Token !== '') {
      headers = headers.append('Authorization', 'Bearer ' + Token);
    }
    if (methodType === HttpStatus.GET) {
      return this.httpClient
        .get<customResponse>(APIBaseUrl + URI, { headers })
        .pipe(
          map((response: customResponse) => {
            this.showAlert(response);
            return {
              Data: response as unknown as T,
              Status: response.TResult,
            };
          }),
          catchError(this.errorHandler),
          finalize(() => { })
        );
    }
    if (methodType === HttpStatus.GETWithId) {
      return this.httpClient
        .get<customResponse>(APIBaseUrl + URI + '/' + Data.toString(), {
          headers,
        })
        .pipe(
          map((response: customResponse) => {
            return {
              Data: response as unknown as T,
              Status: response.TResult,
            };
          }),
          catchError(this.errorHandler),
          finalize(() => { })
        );
    }
    if (methodType === HttpStatus.POST) {
      return this.httpClient
        .post<customResponse>(APIBaseUrl + URI, Data, { headers })
        .pipe(
          map((response: customResponse) => {
            if (URI === ApiUriResource.Login && response.TResult) { } else {
              this.showAlert(response, ShowMsg);
            }
            return {
              Data: response as unknown as T,
              Status: response.TResult,
            };
          }),
          catchError(this.errorHandler),
          finalize(() => { })
        );
    }
    if (methodType === HttpStatus.PUT) {
      return this.httpClient
        .put<customResponse>(APIBaseUrl + URI, Data, { headers })
        .pipe(
          map((response: customResponse) => {
            this.showAlert(response, ShowMsg);
            return {
              Data: response.ResponseData as T,
              Status: response.TResult,
            };
          }),
          catchError(this.errorHandler),
          finalize(() => { })
        );
    }
    if (methodType === HttpStatus.PATCH) {
      return this.httpClient
        .patch<customResponse>(APIBaseUrl + URI, Data, { headers })
        .pipe(
          map((response: customResponse) => {
            this.showAlert(response, ShowMsg);
            return {
              Data: response.ResponseData as T,
              Status: response.TResult,
            };
          }),
          catchError(this.errorHandler),
          finalize(() => { })
        );
    }
    if (methodType === HttpStatus.DELETE) {
      return this.httpClient
        .delete<customResponse>(APIBaseUrl + '/' + Data, { headers })
        .pipe(
          map((response: customResponse) => {
            this.showAlert(response, ShowMsg);
            return {
              Data: response.ResponseData as T,
              Status: response.TResult,
            };
          }),
          catchError(this.errorHandler),
          finalize(() => { })
        );
    }
    return new Observable();
  }

  getHttpFileResponse<T>(Data: any, URI: string = ApiUriResource.filesSaveFile, ShowMsg: boolean = false): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    const Token = localStorage.getItem(`${environment.appVersion}-${environment.AppLocalstorageTokenKeyName}`);
    if (Token !== null && Token !== undefined && Token !== '') {
      headers = headers.append('Authorization', 'Bearer ' + Token);
    }
    return this.httpClient.post<T>(APIBaseUrl + URI, Data, { headers })
      .pipe(
        map((response: T) => {
          return response;
        }),
        catchError(this.errorHandler),
        finalize(() => {
        })
      );
  }
  errorHandler(error: any): Observable<never> {
    let errorMessage: string;
    // if (error?.error) {
    //   this.showAlert(error?.error as unknown as customResponse, true);
    // }
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //this.alertService.showAlert(errorMessage, alertStatus.error);
    /*this.alertService.showAlert(errorMessage, AlertEnum.error);*/
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  showAlert(response: customResponse, ShowMsg: boolean = false): void {
    if (!response.TResult) {
      let msg = '';
      switch (response.ResponseCode) {
        case 400:
          msg = response.Message ?? 'Bad Request';
          break;
        case 404:
          msg = response.Message ?? 'Not Found';
          break;
        case 401:
          msg = response.Message ?? 'Unauthorized';
          break;
        case 408:
          msg = response.Message ?? 'Request Timeout';
          break;
        case 500:
          msg = response.Message ?? 'Internal server Error.';
          break;
        case 5:
          msg = response.Message ?? 'Model Validation Fail.';
          break;
        default:
          msg = response.Message ?? 'Opps! something went wrong';
          break;
      }

      this.alertService.showAlert(msg, alertStatus.error);
    } else if (response.TResult && ShowMsg) {
      this.alertService.showAlert(response.Message, alertStatus.success);
    }
  }
}
