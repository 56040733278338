import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpStatus } from '../_models/enums/httpstatus';
import { updateSuperAdminRequestModel } from '../_models/requestModel/add-super-admin-request-model';
import { changePasswordRequestModel } from '../_models/requestModel/change-password-request-model';
import { ApiUriResource } from '../_models/resources/api-uri-resource';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class profileService {

  constructor(private httpService: HttpService) { }

  getSuperAdminProfile<T>(): Observable<{ Data: T; Status: boolean }> {
    return this.httpService.getHttpResponse<T>(HttpStatus.GETWithId, '', ApiUriResource.getSuperAdminProfileURL, true)
      .pipe(
        map((response: { Data: T; Status: boolean }) => {
          return response;
        })
      );
  }

  updateSuperAdminProfile<T>(model: updateSuperAdminRequestModel): Observable<{ Data: T; Status: boolean }> {
    return this.httpService.getHttpResponse<T>(HttpStatus.POST, model, ApiUriResource.updateSuperAdminProfileURL, true)
      .pipe(
        map((response: { Data: T; Status: boolean }) => {
          return response;
        })
      );
  }

  changePasswordSuperAdminProfile<T>(request: changePasswordRequestModel): Observable<{ Data: T; Status: boolean }> {
    return this.httpService.getHttpResponse<T>(HttpStatus.POST, request, ApiUriResource.changePasswordSuperAdminProfileURL, true)
      .pipe(
        map((response: { Data: T; Status: boolean }) => {
          return response;
        })
      );
  }
}