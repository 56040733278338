<ng-container *ngIf="isActive">
  <ng-container *ngIf="activeDirection === 'asc'">
    <span class="shorting act"></span>
  </ng-container>
  <ng-container *ngIf="activeDirection === 'desc'">
    <span class="shorting"></span>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isActive">
  <span class="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
  </span>
</ng-container>
