import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderInterceptor } from './_helper/loader.interceptor';
import { SuperAdminComponent } from './_modules/super-admin/super-admin.component';
import { AuthService } from './_services/auth.service';
import { LoaderService } from './_services/loader.service';
import { SharedModule } from './_modules/shared/shared.module';
// tslint:disable-next-line:typedef
function appInitializer(authService: AuthService) {
  return () => {
    const Data = new Promise((resolve) => {
      // tslint:disable-next-line: deprecation
      authService.checkUserValid().subscribe().add(resolve);
    });
    return Data;
  };
}
@NgModule({
  declarations: [AppComponent, SuperAdminComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
      deps: [LoaderService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
