import { Injectable } from '@angular/core';
// @ts-ignore
import Noty from 'noty';
import { alertStatus } from '../_models/enums/alertstatus';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class alertService {
  private Options = new Noty().options;

  constructor() {
    /*alert, success, warning, error, info/information*/
    this.Options.type = 'info';
    /*top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight*/
    this.Options.layout = 'bottomRight';
    /*mint, sunset, relax, nest, metroui, semanticui, light, bootstrap-v3, bootstrap-v4*/
    this.Options.theme = 'metroui';
    this.Options.timeout = 3500;
    this.Options.closeWith = ['click', 'button'];
  }

  showAlert(Msg: string, MessageType: alertStatus = alertStatus.success): void {
    // tslint:disable-next-line:label-position
    let type = 'info';
    switch (MessageType) {
      case alertStatus.alert:
        type = 'alert';
        break;
      case alertStatus.error:
        type = 'error';
        break;
      case alertStatus.info:
        type = 'info';
        break;
      case alertStatus.information:
        type = 'information';
        break;
      case alertStatus.success:
        type = 'success';
        break;
      case alertStatus.warning:
        type = 'warning';
        break;
      default:
        type = 'info';
        break;
    }
    this.Options.type = type;
    this.Options.text = Msg;
    new Noty(this.Options).show();
  }

  showAlertWithConformatiom(
    Msg: string,
    BtnOkText: string,
    BtnCancelText: string,
    ObjectData: any,
    ConfirmFunction: any,
    CancelFunction: any,
    MessageType: alertStatus = alertStatus.success
  ): void {
    const type = this.getType(MessageType);
    new Noty({
      text: Msg,
      type,
      layout: 'center',
      dismissQueue: true,
      killer: true,
      closeWith: ['button'],
      buttons: [
        Noty.button(BtnOkText, 'basicBtn mr-1', () => {
          Noty.closeAll();
          if ($.isFunction(ConfirmFunction)) {
            if (ObjectData !== undefined && ObjectData !== null && ObjectData !== '' && !isNaN(ObjectData)) {
              ConfirmFunction(ObjectData);
            } else {
              ConfirmFunction();
            }
          }
        }, { id: 'button1', 'data-status': 'ok' }),
        Noty.button(BtnCancelText, 'btn btn-danger mr-1', () => {
          Noty.closeAll();
          if ($.isFunction(CancelFunction)) {
            if (ObjectData !== undefined && ObjectData !== null && ObjectData !== '' && !isNaN(ObjectData)) {
              CancelFunction(ObjectData);
            } else {
              CancelFunction();
            }
          }
        })
      ]
    }).show();
    $('.noty_buttons,.noty_body').addClass('text-center');
    $('.noty_buttons,.noty_body').addClass('text-dark');
  }

  getType(MessageType: alertStatus): string {
    // tslint:disable-next-line:label-position
    let type: string;
    switch (MessageType) {
      case alertStatus.alert:
        type = 'alert';
        break;
      case alertStatus.error:
        type = 'error';
        break;
      case alertStatus.info:
        type = 'info';
        break;
      case alertStatus.information:
        type = 'information';
        break;
      case alertStatus.success:
        type = 'success';
        break;
      case alertStatus.warning:
        type = 'warning';
        break;
      default:
        type = 'info';
        break;
    }
    return type;
  }
}
