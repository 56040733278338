import { AfterViewInit, Component, DoCheck } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'AQKodeWFM';

  ngAfterViewInit(): void {
    if (document.querySelector("[tabindex='1']")) {
      const selector = document.querySelector("[tabindex='1']") as HTMLFormElement;
      let val = false;
      if (selector) {
        selector.focus();
        val = true;
      }
      console.log(val);
    }
  }
}

