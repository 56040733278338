<ng-template #first>
  <!--  <i class="fa fa-angle-double-left icon-xs" aria-hidden="true"></i>-->
  <span aria-hidden="true">«</span>
  <span class="sr-only">Previous</span>
</ng-template>
<ng-template #previous>
  <!--  <i class="fa fa-angle-left icon-xs" aria-hidden="true"></i>-->
  <span aria-hidden="true">«</span>
  <span class="sr-only">Previous</span>
</ng-template>
<ng-template #next>
  <!-- <i class="fa fa-angle-right icon-xs" aria-hidden="true"></i>-->
  <span aria-hidden="true">»</span>
  <span class="sr-only">Next</span>
</ng-template>
<ng-template #last>
  <!--<i aria-hidden="true" class="fa fa-angle-double-right icon-xs"></i>-->
  <span aria-hidden="true">»</span>
  <span class="sr-only">Next</span>
</ng-template>
<ng-template #ellipsis>...</ng-template>
<ng-template #defaultNumber let-page let-currentPage="currentPage">
  {{ page }}
  <span *ngIf="page === currentPage" class="sr-only"></span>
</ng-template>
<li class="page-item">
  <a
    *ngIf="boundaryLinks"
    [class.disabled]="previousDisabled()"
    aria-label="First"
    class="page-link"
    href
    (click)="selectPage(page - 1); $event.preventDefault()"
    [attr.tabindex]="previousDisabled() ? '-1' : null"
    [attr.aria-disabled]="previousDisabled() ? 'true' : null">
    <ng-template
      [ngTemplateOutlet]="tplFirst?.templateRef || first"
      [ngTemplateOutletContext]="{
      disabled: previousDisabled(),
      currentPage: page
    }"
    ></ng-template>
  </a>
</li>
<ng-container *ngFor="let pageNumber of pages">
  <li class="page-item" [class.active]="pageNumber === page">
    <a
      *ngIf="isEllipsis(pageNumber)"
      class="page-link"
      tabindex="-1"
      aria-disabled="true"
      [class.disabled]="isEllipsis(pageNumber) || disabled"
      [attr.aria-current]="pageNumber === page ? 'page' : null"
    >
      <ng-template
        [ngTemplateOutlet]="tplEllipsis?.templateRef || ellipsis"
        [ngTemplateOutletContext]="{ disabled: true, currentPage: page }"
      ></ng-template>
    </a>
  </li>
  <li class="page-item" [class.active]="pageNumber === page">
    <a
      *ngIf="!isEllipsis(pageNumber)"
      class="page-link"
      (click)="selectPage(pageNumber); $event.preventDefault()"
      [attr.tabindex]="disabled ? '-1' : null"
      [attr.aria-disabled]="disabled ? 'true' : null"
      [class.disabled]="isEllipsis(pageNumber) || disabled"
      [attr.aria-current]="pageNumber === page ? 'page' : null"
    >
      <ng-template
        [ngTemplateOutlet]="tplNumber?.templateRef || defaultNumber"
        [ngTemplateOutletContext]="{
        disabled: disabled,
        $implicit: pageNumber,
        currentPage: page
      }"
      ></ng-template>
    </a>
  </li>
</ng-container>
<li class="page-item">
  <a
    aria-label="Next"
    href
    class="page-link"
    (click)="selectPage(page + 1); $event.preventDefault()"
    [attr.tabindex]="nextDisabled() ? '-1' : null"
    [attr.aria-disabled]="nextDisabled() ? 'true' : null"
    *ngIf="directionLinks"
    [class.disabled]="nextDisabled()"
  >
    <ng-template
      [ngTemplateOutlet]="tplNext?.templateRef || next"
      [ngTemplateOutletContext]="{
      disabled: nextDisabled(),
      currentPage: page
    }"
    ></ng-template>
  </a>
</li>
<!--<a
  aria-label="Last"
  class="btn btn-icon btn-sm btn-primary-soft mr-2 my-1"
  (click)="selectPage(pageCount); $event.preventDefault()"
  [attr.tabindex]="nextDisabled() ? '-1' : null"
  [attr.aria-disabled]="nextDisabled() ? 'true' : null"
  *ngIf="boundaryLinks"
  [class.disabled]="nextDisabled()"
>
  <ng-template
    [ngTemplateOutlet]="tplLast?.templateRef || last"
    [ngTemplateOutletContext]="{
      disabled: nextDisabled(),
      currentPage: page
    }"
  ></ng-template>
</a>-->
