export enum alertStatus {
  alert = 0,
  success,
  warning,
  error,
  info,
  information,
}

export enum EnumStatus {
  success = 200,
  warning,
  error,
  info,
  information,
}


