export const ApiUriResource = {
  //#region "========== Common =========="
  //#endregion

  //#region "========== Files =========="
  filesSaveFile: 'file/SaveFile',
  filesDeleteBulkFile: 'file/DeleteBulkFile',
  filesDeleteFile: 'file/DeleteFile/',
  filesDownloadFile: 'file/DownloadFile/',
  writeDataToExcelStatusWiseTaskReportURL: 'report/WriteDataToExcelStatusWiseTaskReport?',
  WriteDataToExcelAdminUserClientWiseDetailURL: 'report/WriteDataToExcelAdminUserClientWiseDetail?',
  WriteDataToExcelUserWiseDetailReportURL: 'report/WriteDataToExcelUserWiseDetailReport?',
  //#endregion

  //#region "========== Property Value =========="
  PropertyValueById: 'PropertyValue/ById/',
  PropertyValueByBetweenIDs: 'PropertyValue/ByBetweenIDs/',
  PropertyValueByMultipleIds: 'PropertyValue/ByMultipleIds',
  //#endregion

  //#region "========== Authentication =========="
  Login: 'auth/authenticate',
  forgotPasswordURL: 'auth/forgotPassword',
  isValidActivationCodeURL: 'auth/isValidActivationCode',
  resetPasswordURL: 'auth/resetPassword',
  changePasswordURL: 'auth/changePassword',
  //#endregion
  //#region "==========Super Admin==============="
  getSuperAdminURL: 'superAdmin/getAllSuperAdmin?',
  addSuperAdminURL: 'superAdmin/addSuperAdmin',
  editSuperAdminURL: 'superAdmin/editSuperAdmin',
  updateSuperAdminURL: 'superAdmin/updateSuperAdmin',
  //#endregion
  //#region "==========Super Admin Profile==============="
  getSuperAdminProfileURL: 'profile/getSuperAdminProfile',
  updateSuperAdminProfileURL: 'profile/updateSuperAdminProfile',
  changePasswordSuperAdminProfileURL: 'profile/changePassword',
  //#endregion
  //#region "==========Admin==============="
  getAdminURL: 'admin/getAllAdmin?',
  addAdminURL: 'admin/addAdmin',
  editAdminURL: 'admin/editAdmin',
  updateAdminURL: 'admin/updateAdmin',
  getAdminDashboardCountURL: 'user/getAdminDashboardCount',
  getUserDashboardCountURL: 'user/getUserDashboardCount',
  //#region
  //#region "==========Branch ==============="
  getBranchURL: 'branch/getAllBranch?',
  addBranchURL: 'branch/addBranch',
  editBranchURL: 'branch/editBranch',
  updateBranchURL: 'branch/updateBranch',
  //#endregion
  //#region "==========User==============="
  getUserURL: 'user/getAllUser?',
  addUserURL: 'user/addUser',
  editUserURL: 'user/editUser',
  updateUserURL: 'user/updateUser',
  //#endregion
  //#region "=========Upload File================"
  getUploadedSheetURL: 'arFileUpload/getUploadedSheet?',
  addSheetUploadURL: 'arFileUpload/uploadSheet',
  //#endregion
  //#region "=========Task Assignment================"
  getTaskAssignmentDetailsURL: 'taskAssignment/GetAllTask?',
  taskAssignToUserURL: 'taskAssignment/TaskAssignToUser',
  reopenTask: 'taskAssignment/ReopenTask',
  getReAssignUncompletedTaskURL: 'taskAssignment/getReAssignUncompletedTask?',
  reAssignUncompletedTaskToUserURL: 'taskAssignment/reAssignUncompletedTaskToUser',
  viewTaskByIdURL: 'taskAssignment/ViewTaskById?',
  getTaskUserHistoryURL: 'taskAssignment/getTaskUserHistory?',
  userWiseReportURL: 'taskAssignment/userWiseReport?',
  clientWiseReportURL: 'taskAssignment/ClientWiseReport?',
  //#endregion
  //#region "========= Common URL ================"
  getPropertyValueURL: 'common/getPropertyValue',
  getSetNameURL: 'common/getSetName',
  getUserDataURL: 'common/getUserData',
  getAllActiveBranchURL: 'common/getAllActiveBranch',
  getClientDataURL: 'common/getClientData',
  getAssignmentCountByUserURL: 'common/getAssignmentCountByUser?',
  getBucket: 'common/getBucket',
  //#endregion

  //#region "========= User Task Management URL ================"
  getUserTaskURL: 'userTaskManagement/getUserTask?',
  viewUserTaskByIdURL: 'userTaskManagement/ViewTaskById?',
  updateTaskFromUserURL: 'userTaskManagement/updateTaskFromUser',
  updateTaskByUserTaskListURL: 'userTaskManagement/getUserTaskHistory?',
  getUserTaskForTodayFollowUpDateURL: 'userTaskManagement/getUserTaskForTodayFollowUpDate?',
  getUserTaskForMissFollowUpDateURL: 'userTaskManagement/getUserTaskForMissFollowUpDate?',
  updateMultipleTaskFromUserURL: 'userTaskManagement/updateMultipleTaskFromUser',
  //#endregion

  //#region "======= Client Management URL =============="
  getClientURL: 'superAdmin/getClientData?',
  addClientURL: 'superAdmin/createClient',
  editClientURL: 'superAdmin/editClient',
  updateClientURL: 'superAdmin/updateClient',

  //#endregion

  //#region "======= Status Wise Report Management URL =============="
  getStatusWiseTaskReportURL: 'report/getStatusWiseTaskReport?',
  getAdminUserClientWiseDetailReportURL: 'report/getAdminUserClientWiseDetailReport?',
  getUserWiseDetailReportURL: 'report/getUserWiseDetailReport?',
  getSummaryReportURL: 'report/getSummaryReport?'
  //#endregion
};
