export const PageSizes = [3, 5, 10, 15, 25, 50, 100];
export type SortDirection = 'asc' | 'desc' | '';

export class Paginator {
    Page: number;
    PageSize: number;
    Total: number;
    SortColumn: string;
    SortDirection: string; /*'asc' | 'desc' | ''*/
    SearchText: string;
    constructor() {
        this.Page = 1;
        this.PageSize = 10;
        this.Total = 0;
        this.SortColumn = 'CreatedOn';
        this.SortDirection = "desc";
        this.SearchText = "";
    }
}