import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { alertStatus } from '../_models/enums/alertstatus';
import { alertService } from '../_services/alert.service';

@Directive({
  selector: '[appDateIsvalidate]'
})
export class DateIsvalidateDirective {

  @Input() ValidationType: string;


  constructor(
    private ngControl: NgControl,
    private alertService: alertService,
    private elementRef: ElementRef,
    private ngModel: NgModel
  ) {
    this.ValidationType = '';
  }

  @HostListener('change')
  onChange(value: any): void {
    const dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
    if (
      this.ngControl?.control?.value.trim() !== '' &&
      this.ngControl?.control?.value.trim() !== undefined &&
      this.ngControl?.control?.value.trim() !== null
    ) {
      value = this.elementRef?.nativeElement?.value;
      if (this.elementRef?.nativeElement?.value.trim().match(dateformat)) {
        const opera1 = this.elementRef?.nativeElement?.value.split('/');
        const opera2 = this.elementRef?.nativeElement?.value.split('-');
        const lopera1 = opera1.length;
        const lopera2 = opera2.length;
        let pdate = [];
        if (lopera1 > 1) {
          pdate = this.elementRef?.nativeElement?.value.split('/');
        } else if (lopera2 > 1) {
          pdate = this.elementRef?.nativeElement?.value.split('-');
        }
        // tslint:disable-next-line:radix
        const mm = parseInt(pdate[0]);
        // tslint:disable-next-line:radix
        const dd = parseInt(pdate[1]);
        // tslint:disable-next-line:radix
        const yy = parseInt(pdate[2]);

        const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (mm === 1 || mm > 2) {
          if (dd > ListofDays[mm - 1]) {
            this.ngControl.control.setValue('');
            if (!this.ngControl.control.value) {
              this.alertService.showAlert('Invalid date format!', alertStatus.error);
            }
          }
        }
        if (mm === 2) {
          let lyear = false;
          if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
            lyear = true;
          }
          if ((lyear === false) && (dd >= 29)) {
            this.ngControl.control.setValue('');
            if (!this.ngControl.control.value) {
              this.alertService.showAlert('Invalid date format!', alertStatus.error);
            }
          }
          if ((lyear === true) && (dd > 29)) {
            this.ngControl.control.setValue('');
            if (!this.ngControl.control.value) {
              this.alertService.showAlert('Invalid date format!', alertStatus.error);
            }
          }
        }

        if (yy < 1900 || yy > 2200) {
          this.ngControl.control.setValue('');
          this.alertService.showAlert('Invalid Year', alertStatus.error);
        } else if (this.ValidationType !== undefined && this.ValidationType !== '' && this.ValidationType !== null) {


          if (this.ValidationType.toUpperCase() === 'ONLYFUTURE') {
            const CurrentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
            if (CurrentDate >= new Date(yy, mm - 1, dd)) {
              this.ngControl.control.setValue('');
              this.alertService.showAlert('Only Future Date allowed', alertStatus.error);
            }
          }
        }
      } else {
        this.ngControl.control.setValue('');
        if (!this.ngControl.control.value) {
          this.alertService.showAlert('Invalid date', alertStatus.error);
        }
      }
    }
  }

}
