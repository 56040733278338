import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { customResponse } from 'src/app/_models/common/custom-response.model';
import { alertStatus } from 'src/app/_models/enums/alertstatus';
import { changePasswordRequestModel } from 'src/app/_models/requestModel/change-password-request-model';
import { alertService } from 'src/app/_services/alert.service';
import { AuthService } from 'src/app/_services/auth.service';
import { profileService } from 'src/app/_services/profile.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class changePasswordComponent implements OnInit {

  //#region ===========> Variable Declaration <===========
  ShowPWD = false;
  ShowNewPWD = false;
  ShowConPWD = false;
  hasError = false;
  changePasswordForm!: FormGroup;
  shopasswordstatus!: boolean;
  model: changePasswordRequestModel;
  isBusy: boolean = false;
  notMatchPasswords: boolean;
  //#endregion

  //#region ===========> Constructor & OnInit Methods <===========
  constructor(public fb: FormBuilder,
    private authorise: AuthService,
    private AlertService: alertService,
    private ProfileService: profileService) {
    this.model = new changePasswordRequestModel();
    this.notMatchPasswords = true;
  }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(5),
        Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{5,15}')
      ])],
      newPassword: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(5),
        Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{5,15}')
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(5),
        Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{5,15}')
      ])]
    });
  }
  //#endregion

  //#region ===========> CRUD Operation <===========
  changePassword(): void {
    if (this.changePasswordForm.valid) {
      this.isBusy = true;
      this.model.OldPassword = this.changePasswordForm.controls['oldPassword'].value;
      this.model.NewPassword = this.changePasswordForm.controls['newPassword'].value;
      this.model.ConfirmPassword = this.changePasswordForm.controls['confirmPassword'].value;
      if (this.model.ConfirmPassword && this.model.NewPassword) {
        if (this.model.NewPassword !== this.model.ConfirmPassword) {
          this.notMatchPasswords = false;
          this.AlertService.showAlert('password and confirm password are not match', alertStatus.error);
          this.isBusy = false;
        }
        else {
          this.ProfileService.changePasswordSuperAdminProfile<customResponse>(this.model)
            .subscribe((response: { Data: customResponse }) => {
              if (response.Data.TResult) {
                this.authorise.logout();
              }
              this.isBusy = false;
            })
        }
      }
    } else {
      (Object as any).values(this.changePasswordForm.controls).forEach((control: { markAsTouched: () => void; }) => {
        control.markAsTouched();
      });
      this.isBusy = false;
    }
  }
  //#endregion

  //#region =========== Common Methods ===========

  showPassword(): void {
    this.shopasswordstatus = !this.shopasswordstatus;
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

}
